import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import img1 from "../../images/reversalVsManagement/img1.svg";
import vs from "../../images/reversalVsManagement/vs.svg";

import img2 from "../../images/reversalVsManagement/img2.svg";
import img3 from "../../images/reversalVsManagement/img3.svg";
import img4 from "../../images/reversalVsManagement/img4.svg";
import img5 from "../../images/reversalVsManagement/img5.svg";
import img6 from "../../images/reversalVsManagement/img6.svg";
import img7 from "../../images/reversalVsManagement/img7.svg";
import fastrackArrow from "../../images/reversalVsManagement/fastrackArrow.svg";

//css

import * as styles from "./ReversalVsManagement.module.css";

const ReversalVsManagementList = () => {
  return (
    <div className="bgLightpink">
      <div className="container pb-3 twinContainer">
        <div className="row pt-4 align-items-center">
          <div className="col-5 col-md-5 px-2">
            <div className="row">
              <div className="col-12 col-md-4 d-flex px-md-0 justify-content-center pb-3 pt-md-5">
                <div className={styles.logo}>
                  <StaticImage
                    src={"../../images/reversalVsManagement/twinLogoSample.png"}
                    alt="logo"
                    placeholder="blurred"
                  />
                </div>
              </div>
              <div className="col-12 px-3 px-md-0 col-md-8 d-flex align-items-center mt-md-2 pt-md-5 justify-center pb-4 px-0">
                <div className={`${styles.text1} gradientText`}>
                  Twin Diabetes Reversal
                </div>
              </div>
              <div className="col-12 d-flex align-items-end pt-md-3 pb-3 align-self-end justify-content-center">
                {/* <div className={styles.circularImg}></div> */}
                {/* <StaticImage
                  src={"../../images/reversalVsManagement/fastrackArrow.svg"}
                  alt="arrow"
                  placeholder="blurred"
                /> */}
                <img src={fastrackArrow} alt="arrow" placeholder="blurred" />
              </div>
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex flex-column justify-content-center align-items-center">
            <div className={styles.circularImg}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/vs.svg"}
                alt={"vs"}
                placeholder="blurred"
              /> */}
              <img src={vs} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine}></span>
          </div>

          <div className="col-5 col-md-5">
            <div className="row">
              <div className="col-12 px-4 px-md-4 col-md-8 offset-md-2 pt-0 pt-md-4 pb-1 pb-md-4">
                <div className={styles.text2}>
                  Every Other Reversal Program in India
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 d-flex align-self-center pt-3 pt-md-2 justify-content-center">
                <div>
                  {/* <StaticImage
                    alt="images"
                    placeholder="blurred"
                    src="../../images/reversalVsManagement/fastrackArrow.svg"
                  /> */}
                  <img src={fastrackArrow} alt="arrow" placeholder="blurred" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row mb-2  ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center pt-3 pb-3 px-2 px-0 d-flex justify-content-center align-items-center">
            <div className={`px-3 px-md-3 ${styles.text22}`}>
              Validated by 13 International Medical Journals
            </div>
          </div>

          <div className="col-2 px-0 col-md-2 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img1.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}
              <img src={img1} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 col-md-5 px-3 px-md-0 justify-content-center d-flex align-items-center">
            <div className={styles.text222}>Very limited research-backing</div>
          </div>
        </div>
        <div className={`row mb-2  ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center ps-2 pt-3 pb-3  px-2 px-0 d-flex align-items-center">
            <div className="row">
              <div className="col-12 d-flex justify-content-center pb-1 px-0">
                <div className={`${styles.text111} gradientText`}>300+</div>
              </div>
              <div className="col-12">
                <div className={`px-4 px-md-0 ${styles.text22}`}>
                  renowned Doctors are a part of Twin
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex justify-content-md-center align-items-md-center px-0 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img2.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}
              <img src={img2} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 col-md-5 px-4 px-md-0 d-flex justify-content-md-center align-items-md-center px-0 d-flex align-items-center">
            <div className={styles.text222}>
              Generic, One-size-fits-all. Treats: symptoms
            </div>
          </div>
        </div>

        <div className={`row mb-2  ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center ps-2 pt-3 pb-3 px-2 d-flex align-items-center">
            <div className="row">
              <div className="col-12 d-flex justify-content-center pb-1 px-0">
                <div className={`${styles.text111} gradientText`}>89%*</div>
              </div>
              <div className="col-12">
                <div className={`px-3 px-md-0  ${styles.text22}`}>
                  of members in-Reversal within 90 Days
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex justify-content-md-center align-items-md-center px-0 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img3.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}
              <img src={img3} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 px-3 px-md-0 col-md-5 d-flex justify-content-md-center align-items-md-center px-0 d-flex align-items-center">
            <div className={styles.text222}>Non-validated outcomes</div>
          </div>
        </div>

        <div className={`row mb-2  ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center ps-2 pt-3 pb-3 px-2 px-0 d-flex align-items-center">
            <div className="row">
              <div className="col-12 d-flex justify-content-center pb-1 px-0">
                <div className={`${styles.text111} gradientText`}>24*7</div>
              </div>
              <div className="col-12">
                <div className={`px-3 px-md-0  ${styles.text22}`}>
                  Monitoring using wearable sensors
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex justify-content-md-center align-items-md-center px-0 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img4.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}
              <img src={img4} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 col-md-5 px-3 px-md-0 d-flex justify-content-md-center align-items-md-center px-0 d-flex align-items-center">
            <div className={styles.text222}>Minimal health monitoring</div>
          </div>
        </div>

        <div className={`row mb-2  ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center ps-2 pt-3 pb-3 px-2 px-0 d-flex align-items-center">
            <div className={`px-4 px-md-3 ${styles.text22}`}>
              Enabled by Artificial Intelligence-based technology
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex justify-content-md-center align-items-md-center px-0 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img5.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}

              <img src={img5} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 col-md-5 px-3 px-md-0 d-flex justify-content-md-center align-items-md-center px-0 d-flex align-items-center">
            <div className={styles.text222}>
              Very little personalisation of plans
            </div>
          </div>
        </div>

        <div className={`row mb-2 ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center ps-2 pt-3 pb-3 px-2 px-0 d-flex align-items-center">
            <div className={`px-3 px-md-0 ${styles.text22}`}>
              In-built algorithm to predict your reversal date
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex justify-content-md-center align-items-md-center px-0 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img6.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}
              <img src={img6} alt="arrow" placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 col-md-5 px-3 px-md-0 d-flex justify-content-md-center align-items-md-center px-0 d-flex justify-content-center align-items-center">
            <div className={styles.text222}>No such thing!</div>
          </div>
        </div>

        <div className={`row mb-2  ${styles.conco}`}>
          <div className="col-5 col-md-5 d-flex justify-content-md-center align-items-md-center ps-2 pt-3 pb-3 px-2 px-0 d-flex align-items-center">
            <div className={`px-3 px-md-3 ${styles.text22}`}>
              Covers doctor consultations, blood tests, medicines, recipes, & so
              much more
            </div>
          </div>

          <div className="col-2 col-md-2 d-flex justify-content-md-center align-items-md-center px-0 d-flex flex-column justify-content-center align-items-center">
            <span className={styles.verticalLine22}></span>

            <div className={styles.circularImg2}>
              {/* <StaticImage
                src={"../../images/reversalVsManagement/img7.svg"}
                alt={"vs"}
                quality={100}
                placeholder="blurred"
              /> */}
              <img src={img7} alt={"vs"} placeholder="blurred" />
            </div>
            <span className={styles.verticalLine22}></span>
          </div>

          <div className="col-5 px-3 px-md-3 col-md-5 d-flex justify-content-md-center align-items-md-center px-0 d-flex align-items-center">
            <div className={styles.text222}>
              We challenge you to find such an elaborate program!
            </div>
          </div>
        </div>

        <div className="row mt-4 mb-4">
          <div className="col-12 d-block d-md-none">
            <div className={styles.textCo1}>*Based on Randomised Control</div>
            <div className={styles.textCo1}>Trials conducted by Twin</div>
          </div>
          <div className="col-12 px-0 d-none d-md-flex">
            <div className={styles.textCo1}>
              *Based on Randomised Control Trials conducted by Twin
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReversalVsManagementList;
