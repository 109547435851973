import React from "react";

import * as styles from "./ReversalVsManagement.module.css";

const ReversalVsManagementBanner = () => {
  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div className="row">
          <div className="col-12 pt-5 pb-md-2 pb-3">
            <div className={styles.title}>
              What makes Twin better than others?
            </div>
          </div>
          <div className="col-12 pb-5">
            <div className={styles.subTitle}>
              Being a Diabetic is tough. we will make it easy for you to choose
              the best for your treatment
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReversalVsManagementBanner;
