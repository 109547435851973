// extracted by mini-css-extract-plugin
export var circularImg = "ReversalVsManagement-module--circularImg--o60VL";
export var circularImg2 = "ReversalVsManagement-module--circularImg2--ARatb";
export var conco = "ReversalVsManagement-module--conco--AFAdK";
export var logo = "ReversalVsManagement-module--logo--KbFZK";
export var subTitle = "ReversalVsManagement-module--subTitle--FV4Ym";
export var text1 = "ReversalVsManagement-module--text1--t-E+1";
export var text111 = "ReversalVsManagement-module--text111--Lp0dQ";
export var text2 = "ReversalVsManagement-module--text2--W2Fz0";
export var text22 = "ReversalVsManagement-module--text22--GBF7M";
export var text222 = "ReversalVsManagement-module--text222--b7czb";
export var textCo1 = "ReversalVsManagement-module--textCo1--xbTAb";
export var title = "ReversalVsManagement-module--title---nQdi";
export var verticalLine = "ReversalVsManagement-module--verticalLine--OJqUX";
export var verticalLine2 = "ReversalVsManagement-module--verticalLine2--FOfbZ";
export var verticalLine22 = "ReversalVsManagement-module--verticalLine22--RyEln";