import React from "react";
import ReversalVsManagementBanner from "./ReversalVsManagementBanner";
import ReversalVsManagementList from "./ReversalVsManagementList";

const ReversalVsManagement = props => {
  return (
    <>
      <ReversalVsManagementBanner />
      <ReversalVsManagementList />
    </>
  );
};

export default ReversalVsManagement;
